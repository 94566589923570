import classNames from "classnames";
import { FormikErrors } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import {
    BUNDLES_ADD_ONS,
    ChannelBundle,
    CorporateBundle,
    MANAGEMENT_CONSOLE_LICENSE,
    ProcessorBundle,
    SMB_BUNDLE,
} from "components/licenses/bundles";
import { showOnlyTokenLicense } from "components/licenses/common";
import { createLicense } from "components/licenses/delivery-history/AddLicenseDeliveryView";
import DeliveryFormContent, { DeliveryForm, License } from "components/licenses/delivery-history/DeliveryFormContent";
import LicenseSelectionForm from "components/licenses/delivery-history/LicenseSelectionForm";
import StaticTable from "components/support/api-guide/StaticTable";
import creationFormStyles from "components/tenants/add-tenant/add-tenant.scss";
import {
    FormValues,
    generateSelectableLicenses,
    isProductAddOn,
    isTokenLicense,
} from "components/tenants/add-tenant/AddTenantForm";
import editTenantStyle from "components/tenants/edit-tenant/edit-tenant.scss";
import { TenantsLicenses } from "components/tenants/edit-tenant/EditTenantView";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { LicenseData, MAX_DATE, MIN_DATE } from "domain/licenses";
import { CombinedTier, LicensingModel } from "domain/tenants";
import { ProductToRateList } from "services/licenses/LicenseService";
import { formatExpirationDate } from "utils/format";

import testIds from "testIds.json";

interface Props {
    defaultTenantLicenses: LicenseData[] | undefined;
    allTenantLicenses: TenantsLicenses[];
    tier: CombinedTier;
    licensingModel: LicensingModel;
    selectedLicenses: License[];
    setSelectedLicenses: (licenses: License[]) => void;
    deliveryDetails: DeliveryForm;
    errors: FormikErrors<DeliveryForm> | FormikErrors<FormValues>;
    handleChange: (
        e:
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    rateVersions?: ProductToRateList[];
    inEdit: boolean;
}

function generateDropdownLicenses(
    selectedTier: CombinedTier,
    dropdownLicenses: { productName: string; productId: string }[],
    licensingModel: LicensingModel
) {
    generateSelectableLicenses(dropdownLicenses, selectedTier, licensingModel);
}

function manageLicenseList(
    eachInAllLicenses: { productId: string; productName: string },
    predefinedLicenses: License[],
    selectedTier: string,
    tenantLicenses: LicenseData[] | undefined
) {
    const splitSmbType = selectedTier.split("_");
    const availableValue = splitSmbType[splitSmbType.length - 1];
    const parentLicense = tenantLicenses?.find(
        (eachParentLicense) => eachParentLicense.type == eachInAllLicenses.productId
    );
    if (parentLicense) {
        predefinedLicenses.push(
            createLicense(
                parentLicense.type || eachInAllLicenses.productId,
                0,
                eachInAllLicenses.productName,
                parentLicense.expirationDate,
                predefinedLicenses.length,
                parentLicense.available,
                SMB_BUNDLE.includes(selectedTier) ? Number(availableValue) : 1,
                parentLicense.expirationDate
            )
        );
    }
}

export function populateWithLicenses(
    selectedTier: CombinedTier,
    licensingModel: LicensingModel,
    tenantLicenses: LicenseData[] | undefined
) {
    const dropdownLicenses: { productName: string; productId: string }[] = [];
    generateDropdownLicenses(selectedTier, dropdownLicenses, licensingModel);
    const predefinedLicenses: License[] = [];
    dropdownLicenses.forEach((eachInAllLicenses) => {
        if (eachInAllLicenses.productId == MANAGEMENT_CONSOLE_LICENSE) {
            if (
                selectedTier == CorporateBundle.ENTERPRISE ||
                selectedTier == ProcessorBundle.PRO_SCALE ||
                selectedTier == ChannelBundle.PRO_SCALE
            ) {
                manageLicenseList(eachInAllLicenses, predefinedLicenses, "", tenantLicenses);
            }
        } else if (!BUNDLES_ADD_ONS.includes(eachInAllLicenses.productId)) {
            manageLicenseList(eachInAllLicenses, predefinedLicenses, selectedTier, tenantLicenses);
        }
    });
    return predefinedLicenses;
}

export const DeliveryTab = (props: Props) => {
    const { t } = useTranslation();
    const dropdownLicenses: { productName: string; productId: string }[] = [];
    const filteredTenantLicenses: LicenseData[] = [];
    if (props.defaultTenantLicenses !== undefined && props.defaultTenantLicenses !== null) {
        if (showOnlyTokenLicense(props.licensingModel)) {
            filteredTenantLicenses.push(
                ...props.defaultTenantLicenses?.filter((each) => isTokenLicense(each.type) || isProductAddOn(each.type))
            );
        } else {
            filteredTenantLicenses.push(...props.defaultTenantLicenses?.filter((each) => !isTokenLicense(each.type)));
        }
    }
    const fetchLicences = () => {
        generateDropdownLicenses(props.tier, dropdownLicenses, props.licensingModel);
        const allLicenses: License[] = [];
        filteredTenantLicenses.forEach((tenantLicense) => {
            if (isExpirationDateValid(tenantLicense.expirationDate) && tenantLicense.available > 0) {
                const productDetails = dropdownLicenses.find((each) => each.productId === tenantLicense.type);
                if (typeof productDetails === "undefined") {
                    return;
                }
                allLicenses.push(
                    createLicense(
                        tenantLicense.type,
                        0,
                        productDetails.productName,
                        tenantLicense.expirationDate,
                        allLicenses.length,
                        tenantLicense.available,
                        0,
                        tenantLicense.expirationDate
                    )
                );
            }
        });
        return allLicenses;
    };
    const isExpirationDateValid = (expiration: string): boolean => {
        const expirationDate = new Date(expiration);
        return expirationDate >= MIN_DATE && expirationDate <= MAX_DATE;
    };
    const allAvailableLicenses = fetchLicences();
    function showTenantsLicenses() {
        return (
            <StaticTable
                headers={[
                    {
                        value: t("EditCustomerView.oldLicensesToBeDeleted.licenseType"),
                    },
                    {
                        value: t("EditCustomerView.oldLicensesToBeDeleted.totalOfLicenses"),
                    },
                    {
                        value: t("EditCustomerView.oldLicensesToBeDeleted.remainingLicenses"),
                    },
                    {
                        value: t("EditCustomerView.oldLicensesToBeDeleted.expirationDate"),
                    },
                    {
                        value: t("EditCustomerView.oldLicensesToBeDeleted.licensePool"),
                    },
                ]}
                cells={props.allTenantLicenses.map((license) => {
                    return [
                        <div
                            key={"licenseType" + license.product}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.licenseTypeSelect.itself
                            }
                        >
                            {license.product}
                        </div>,
                        <div
                            key={"totalOfLicenses" + license.available}
                            className={classNames(editTenantStyle.staticDatalabel)}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.totalOfLicensesLabel
                            }
                        >
                            {license.total}
                        </div>,
                        <div
                            key={"remainingLicenses" + license.remainingLicenses}
                            className={classNames(editTenantStyle.staticDatalabel)}
                            data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.availableLabel}
                        >
                            {license.remainingLicenses}
                        </div>,
                        <div
                            key={"expirationDate" + license.expirationDate}
                            className={editTenantStyle.staticDatalabel}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.expirationDateInput.itself
                            }
                        >
                            {formatExpirationDate(license.expirationDate)}
                        </div>,
                        <div
                            key={"licensePool" + license.licensePool}
                            className={classNames(editTenantStyle.staticDatalabel)}
                        >
                            {license.licensePool}
                        </div>,
                    ];
                })}
            />
        );
    }

    function computeEquivalentOfCommonLicenses() {
        let equivalentAmount = 0;
        props.allTenantLicenses.forEach((eachLicense) => {
            if (props.rateVersions) {
                const latestRates = props.rateVersions[0].productToRate;
                const rate = latestRates.find((eachRate) => eachRate.productId === eachLicense.productId)?.rate;
                if (rate) {
                    equivalentAmount += (eachLicense.remainingLicenses * rate) / 100;
                }
            }
        });
        return equivalentAmount;
    }

    function computeAddOnsCount() {
        return props.allTenantLicenses.filter((each) => BUNDLES_ADD_ONS.includes(each.productId)).length;
    }

    return (
        <div className={classNames(editTenantStyle.scroller)}>
            <DeliveryFormContent
                availableLicenses={allAvailableLicenses}
                inTenantCreation={true}
                ownedLicenses={[]}
                selectedLicenses={props.selectedLicenses}
                setSelectedLicenses={(licenses) => {
                    props.setSelectedLicenses(licenses);
                }}
                deliveryForm={props.deliveryDetails}
                errors={props.errors}
                handleChange={props.handleChange}
                licensingModel={props.licensingModel}
                rateVersions={props.rateVersions}
                selectedTier={props.tier}
            />
            <div className={classNames(editTenantStyle.deliverDetailsTableContainer, creationFormStyles.deliveryTable)}>
                <LicenseSelectionForm
                    newDeal={true}
                    availableLicenses={allAvailableLicenses.sort((currentLicense, nextLicense) =>
                        currentLicense.productName.localeCompare(nextLicense.productName)
                    )}
                    ownedLicenses={[]}
                    globalAmount={props.deliveryDetails.amount}
                    globalExpirationDate={props.deliveryDetails.expirationDate}
                    selectedLicenses={props.selectedLicenses}
                    setSelectedLicenses={(licenses) => {
                        props.setSelectedLicenses(licenses);
                    }}
                />
                {props.inEdit ? (
                    <div>
                        <TextBlock>
                            <strong>{t("EditCustomerView.oldLicensesToBeDeleted.note")}</strong>
                        </TextBlock>
                        <TextBlock>
                            {t("EditCustomerView.equivalentCounts", {
                                commonLicenseAmount: computeEquivalentOfCommonLicenses(),
                                addOnsCount: computeAddOnsCount(),
                            })}
                        </TextBlock>
                        {showTenantsLicenses()}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
